'use strict'

angular
  .module 'mundoAdmin.locations'
  .config ($stateProvider) ->
    $stateProvider
      .state 'locations',
        url: '/locations'
        parent: 'admin'
        abstract: true
        views:
          '@admin':
            templateUrl: 'mundo-admin/locations/views/locations.tpl.html'

      .state 'locations.overview',
        url: '/overview'
        views:
          'locations@locations':
            templateUrl: 'mundo-components/mundo-data-table/views/mundo-data-table.tpl.html'
            controller: 'DataTableCtrl'
            controllerAs: 'listCtrl'
            resolve:
              entityManager: (LocationManager) ->
                LocationManager
              pageTitle: ->
                'app.admin.pageTitles.locations.locations'
              pageDescription: ->
                'app.admin.descriptions.locations.locations'
              deleteBoxTitle: ->
                'app.admin.actions.locations.deleteLocation'
              deleteBoxBody: ->
                'app.admin.actions.locations.deleteLocation'
              AddBoxTitle: ->
                'app.admin.actions.locations.addLocation'
              editBoxTitle: ->
                'app.admin.actions.locations.editLocation'
              loadFilterParams: ->
                {}
